
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { useReCaptcha } from 'vue-recaptcha-v3';

import FormField from '@/components/forms/FormField.vue';

import { ResetPassword } from '@/models/ResetPasswordModel';

import { ResetPasswordSchema } from '@/schemas/common.schemas';
import { Getters, Actions } from '@/store/enums/ForgotPasswordEnums';
import { mapActions, mapGetters } from 'vuex';
import toasts from '@/utils/toasts';
import router from '@/router';

const initialValues = {} as unknown as ResetPassword;

export default defineComponent({
  name: 'password-reset-form',
  components: {
    Form,
    FormField,
  },
  data: () => ({
    recaptcha_token: null,
    recaptcha_error: false,
    form: {} as typeof Form,
    formData: initialValues,
    loading: false,
    submitButton: {} as HTMLElement,
    formErrors: {},
  }),
  mounted() {
    // setup the form reference
    this.form = this.$refs.resetPasswordForm as typeof Form;

    this.submitButton = this.$refs.submitButton as HTMLElement;
  },
  computed: {
    ...mapGetters({
      actionError: Getters.GET_FORGOT_PASSWORD_ACTION_ERROR,
    }),

    formSchema() {
      return ResetPasswordSchema;
    },
  },
  methods: {
    ...mapActions({
      resetPassword: Actions.RESET_PASSWORD,
    }),

    resetForm() {
      // Reset the form validation and values
      this.form.resetForm();

      // Reset the form values
      this.formData = initialValues;
      this.formErrors = {};
    },

    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },

    async submitResetPasswordForm(values) {
      if (this.loading) return;
      this.loading = true;

      const token = await this.recaptcha();

      if (!token) {
        this.recaptcha_error = true;
        this.loading = false;
        return;
      }

      this.formData = values;
      this.formData.token = this.$route.params.token as string;
      this.formData.recaptcha_token = token;

      this.resetPassword(this.formData)
        .then(() => {
          this.loading = false;

          this.resetForm();

          toasts.success('Password successfully modified', () => {
            router.push({ name: 'sign-in' });
          });
        })
        .catch(() => {
          const { errors, message } = this.actionError;
          toasts.error(errors, message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
  },
  setup() {
    const recaptchaInstance = useReCaptcha();

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaInstance?.recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await recaptchaInstance?.executeRecaptcha('register');

      // Do stuff with the received token.
      return token;
    };

    return {
      recaptcha,
    };
  },
});
