
import { defineComponent } from 'vue';

import AlertInfo from '@/components/alerts/AlertInfo.vue';
import ResetPasswordForm from '@/components/forms/forgot-password/ResetPasswordForm.vue';
import MainLogo from '@/layout/header/partials/MainLogo.vue';

export default defineComponent({
  name: 'reset-password-page',
  components: {
    MainLogo,
    AlertInfo,
    ResetPasswordForm,
  },
});
