
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'alert-info',
  props: {
    title: { default: 'Information', type: String },
    text: { default: '', type: String, required: true },
    color: { default: 'primary', type: String, required: true },
  },
});
